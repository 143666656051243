<template> 
    <div class="banner inner-banner">
      <img
        src="/assets/images/press-release.jpg"
        alt=""
        class="w-100 zoom-out"
      />
      <div class="banner-text" data-aos="fade-up">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8">
              <h1>{{ translationText.text.Blog }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="faq-page">
      <div class="container">
        <div class="row aos-init aos-animate" data-aos="fade-up">
          <div class="col-lg-12">       
            <h4>{{ blog.title }}</h4>
            <div class="blog-date">{{blog.formatted_date}}</div>
            <div v-html="blog.content"></div>
          </div>
        </div>
      </div>
    </section>
    <ProductListQuiz></ProductListQuiz>
  </template>
  
  <script setup>
  import { defineComponent, ref, onMounted, watch, defineProps } from "vue";
  import { useRoute } from "vue-router"; 
  import { storeToRefs } from "pinia";
  import axios from "axios"; // Import axios
  import ProductListQuiz from "../components/Product/ProductListQuiz";
  import { useLoginStore } from "../stores/login";
  import { userLanguage } from "../stores/language";
  
  const userLanguages = userLanguage();
  const { languageCode } = storeToRefs(userLanguages);
  const userLogin = useLoginStore();
  const props = defineProps(["baseURL"]);
  const route = useRoute(); // Initialize route
  let blogSlug = route.params.blogSlug;

  console.log("BlogSlug", route.params);
  
  const translationTextEn = ref({
    text: {
        Blog: "Blog"
    },
    language: "",
});

const translationText = ref({
    text: {
        Blog: "Blog"
    },
    language: "",
});
  
  const translatText = () => {
    translationText.value.language = userLanguages.getLanguageCode
      ? "/" + userLanguages.getLanguageCode
      : "";
    if (userLanguages.getLanguageCode !== "en") {
      const apiUrl = props.baseURL + "translateany";
      console.log("translationText.value", translationText.value);
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response translate Testimonials :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      translationText.value = translationTextEn.value;
    }
  };
  
  const blog = ref({}); // Initialize as an object
  const languageUrl = userLanguages.getLanguageCode ? `/${userLanguages.getLanguageCode}` : '';
  
  // Fetch products from the API
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${props.baseURL}blog-details/${blogSlug}${languageUrl}`);
      blog.value = response.data.blog_content[0]; 
      
    } catch (error) {
      console.error('Error fetching blog data:', error);
    }
  };
  
  watch(languageCode, () => {
    location.reload();
    translatText();
    fetchBlogs();
  });
  
  onMounted(() => {
    translatText();
    fetchBlogs();
  });
  </script>
  