<template>
    <div class="banner inner-banner">
        <img src="/assets/images/press-release.jpg" alt="" class="w-100 zoom-out" />
        <div class="banner-text" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8">
                        <h1>{{ translationText.text.Blog }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="products-related-tabs press-tabs" data-aos="fade-up">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="press-release">
                    <div class="row">

                        <div v-for="(blog, index) in blogs" :key="blog.id"
                            class="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div class="blog-container">
                                <div class="blog-description">
                                    <div class="blog-date">{{ blog.formatted_date }}</div>
                                    <div class="blog-title">
                                        <h4>
                                            <a href="">{{ blog.title }}</a>
                                        </h4>
                                    </div>
                                    <p>
                                    <div v-html="blog.content_preview"></div>
                                    </p>
                                    <router-link :to="`/blog-details/${blog.slug}`"
                                        class="btn btn-primary">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
   </section>


    <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { ref, onMounted, watch, defineProps } from "vue";
import { storeToRefs } from "pinia";
import axios from "axios";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { userLanguage } from "../stores/language";

const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);

const translationTextEn = ref({
    text: {
        Blog: "Blog"
    },
    language: "",
});

const translationText = ref({
    text: {
        Blog: "Blog"
    },
    language: "",
});

const blogs = ref([]);
const languageUrl = userLanguages.getLanguageCode ? `/${userLanguages.getLanguageCode}` : "";

// Fetch translations
const translatText = () => {
    translationText.value.language = userLanguages.getLanguageCode
        ? "/" + userLanguages.getLanguageCode
        : "";
    if (userLanguages.getLanguageCode !== "en") {
        const apiUrl = props.baseURL + "translateany";
        axios
            .post(apiUrl, translationText.value)
            .then((response) => {
                translationText.value = response.data;
            })
            .catch((error) => {
                console.error("Error fetching translations:", error);
            });
    } else {
        translationText.value = translationTextEn.value;
    }
};

// Fetch press releases
const fetchBlogs = async () => {
    try {
        const response = await axios.get(`${props.baseURL}blog-list${languageUrl}`);
        blogs.value = response.data.blog_list; // Assuming response contains a list of press releases
    } catch (error) {
        console.error('Error fetching press release data:', error);
    }
};

watch(languageCode, () => {
    location.reload(); // Consider handling translation changes without reloading the page
});

onMounted(() => {
    translatText();
    fetchBlogs();
});
</script>