<template>
  <div class="banner inner-banner">
    <img src="/assets/images/press-release.jpg" alt="" class="w-100 zoom-out" />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8">
            <h1>{{ translationText.text.PressRelease }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="products-related-tabs press-tabs" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-tabs mb-md-5 mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation"><button aria-controls="" aria-selected="true"
                class="nav-link active" data-bs-target="#news-cymcorp" data-bs-toggle="tab" id="overview-tab" role="tab"
                type="button">Press Release</button></li>
            <li class="nav-item" role="presentation"><button aria-controls="" aria-selected="false" class="nav-link"
                data-bs-target="#featured" data-bs-toggle="tab" id="direction-tab" role="tab" type="button">Press
                Room</button></li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div aria-labelledby="" class="tab-pane fade show active" id="news-cymcorp" role="tabpanel">
              <div class="press-release">
                <div class="row">

                  <div v-for="(pressReleaseContent, index) in pressReleaseContents" :key="pressReleaseContent.id"
                    class="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div class="blog-container">
                      <div class="blog-description">
                        <div class="blog-date">{{ pressReleaseContent.formatted_date }}</div>
                        <div class="blog-title">
                          <h4>
                            <a href="">{{ pressReleaseContent.title }}</a>
                          </h4>
                        </div>
                        <p>
                        <div v-html="pressReleaseContent.content_preview"></div>
                        </p>
                        <router-link :to="`/page/press-release-details/${pressReleaseContent.slug}`"
                          class="btn btn-primary">
                          Read More
                        </router-link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div aria-labelledby="" class="tab-pane fade" id="featured" role="tabpanel">
              <div class="mediakit-card">
                <h2>Cymcorp Media Kit</h2>

                <p>Find all of Cymcorp&#39;s brand assets here. Can&#39;t find what you&#39;re looking for?<br />
                  Reach out to <a href="mailto:r.gauthier@cymcorp.ca">r.gauthier@cymcorp.ca</a></p>
              </div>

              <div class="press-room-list">
                <form>
                  <div class="press-room-row mb-4">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Logos</span> 3 Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-011.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Cymcorp Logo</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-012.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Cymcorp Logo-White</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-016.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Cymcorp Logo-Gold</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="press-room-row mb-4">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Secondary Branding</span> 3
                          Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-014.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">CYM Branding Logo</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-015.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">CYM Branding Logo-White</label>
                            </div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/logo_final-016.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">CYM Branding Logo-Gold</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="press-room-row mb-4">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Product Labels</span> 5
                          Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version1.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">GSH Complex</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/prezerv-derma1.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Derma</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Triozymecomplex-dark01.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Triozyme</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Triozymecomplex-dark02.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Unevie</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Triozymecomplex-dark03.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Viprox</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="press-room-row mb-4">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Humanitarian Labels</span> 5
                          Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version11.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">GSH Complex</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version12.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Derma</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version13.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Triozyme</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version14.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Unevie</label></div>

                            <div class="attachcol">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/VIPROX-Humanatarian.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Viprox</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href=""><img alt=""
                                  src="https://devenv.marketingplatform.ca/cymcorp-refine/images/attach-icon.png" />
                                2</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="press-room-row mb-4">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Photo Assets</span>
                          12&Acirc;&nbsp;Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version16.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Home Hero Banner</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version17.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Triozyme</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version18.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">GSH Complex</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version19.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Unevie</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version20.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Derma</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version21.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Gold</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version22.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Derma</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version23.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Prezerv Bronze</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version24.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">VIPROX</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version25.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">VIPROX JUNIOR</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/Front---dark-version26.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">GSH COMPLEX</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="press-room-row">
                    <div class="all-check">
                      <div class="form-check"><label class="form-check-label"><span>Leadership Headshots</span>
                          2&Acirc;&nbsp;Assets</label></div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/ROBERT-GAUTHIER.png" />
                          </div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Robert Gauthier</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="press-room-card">
                          <div class="press-room-thumb"><img alt=""
                              src="https://devenv.marketingplatform.ca/cymcorp-refine/images/MARISOL-LOPEZ.png" /></div>

                          <div class="press-room-body">
                            <div class="form-check"><label class="form-check-label">Marisol Lopez</label></div>

                            <div class="attachcol"><a class="btn btn-attach" href="">JPG</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { ref, onMounted, watch, defineProps } from "vue";
import { storeToRefs } from "pinia";
import axios from "axios";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { userLanguage } from "../stores/language";

const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);

const translationTextEn = ref({
  text: {
    PressRelease: "Press Release"
  },
  language: "",
});

const translationText = ref({
  text: {
    PressRelease: "Press Release"
  },
  language: "",
});

const pressReleaseContents = ref([]);
const languageUrl = userLanguages.getLanguageCode ? `/${userLanguages.getLanguageCode}` : "";

// Fetch translations
const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if (userLanguages.getLanguageCode !== "en") {
    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching translations:", error);
      });
  } else {
    translationText.value = translationTextEn.value;
  }
};

// Fetch press releases
const fetchPressReleases = async () => {
  try {
    const response = await axios.get(`${props.baseURL}press-releases-list${languageUrl}`);
    pressReleaseContents.value = response.data.press_release_contents; // Assuming response contains a list of press releases
  } catch (error) {
    console.error('Error fetching press release data:', error);
  }
};

watch(languageCode, () => {
  location.reload(); // Consider handling translation changes without reloading the page
});

onMounted(() => {
  translatText();
  fetchPressReleases();
});
</script>
