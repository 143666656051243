<template>
  <div class="banner inner-banner">
    <img
      src="/assets/images/testimonial-banner.jpg"
      alt=""
      class="w-100 zoom-out"
    />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8">
            <h1>{{ translationText.text.successStory }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="testimonial-area">
    <div class="container">
      <div
        class="row justify-content-center aos-init aos-animate"
        data-aos="fade-up"
      >
        <div class="title col-lg-8 text-center">
          <h2>{{ translationText.text.successStory }}</h2>
          <p>{{ translationText.text.successStorySubText }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <!-- Tabs Navigation -->
            <ul class="nav nav-tabs" id="productTabs" role="tablist">
              <li v-for="(product, index) in products" :key="product.id" class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: index === 0 }"
                  :id="`tab-${product.id}`"
                  data-bs-toggle="tab"
                  :href="`#product-${product.id}`"
                  role="tab"
                  :aria-controls="`product-${product.id}`"
                  :aria-selected="index === 0 ? 'true' : 'false'"
                >
                  {{ product.name }} <!-- Product name from API -->
                </a>
              </li>
            </ul>

            <!-- Tabs Content -->
            <div class="tab-content" id="productTabContent">
              <div
                v-for="(product, index) in products"
                :key="product.id"
                class="tab-pane fade"
                :class="{ show: index === 0, active: index === 0 }"
                :id="`product-${product.id}`"
                role="tabpanel"
                :aria-labelledby="`tab-${product.id}`"
              >      

                <!-- Splide Slider for Product Reviews -->
                <Splide v-if="product.reviews && product.reviews.length > 0" :options="options">
                  <SplideSlide v-for="(review, reviewIndex) in product.reviews" :key="reviewIndex">
                    <div class="testimonial-contents">
                      <span class="quote-image"><img src="/assets/images/quote1.png" /></span>
                      <div v-html="review.content"></div> <!-- Review content from API -->
                      <h4>{{ review.name }}</h4> <!-- Reviewer name -->
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { defineComponent, ref, onMounted, watch, defineProps } from "vue";
import { mapState, storeToRefs } from "pinia";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { useLoginStore } from "../stores/login";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { userLanguage } from "../stores/language";
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const options = {
  rewind: true,
  //destroy: true,
  type: "loop",
  perPage: 1,
  pagination: false,
  gap: "2em",
  breakpoints: {
    1199: {
      perPage: 1,
    },
    767: {
      perPage: 1,
    },
  },
  //heightRatio: 0.5625,
};

const translationTextEn = ref({
  text: {
    successStory: "Success Stories",
    successStorySubText:
      "Learn what others had to say after using our line of products."
    },
  language: "",
});
const translationText = ref({
  text: {
    successStory: "Success Stories",
    successStorySubText:
      "Learn what others had to say after using our line of products."
    },
  language: "",
});

const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if (userLanguages.getLanguageCode != "en") {
    const apiUrl = props.baseURL + "translateany";
    console.log("translationText.value", translationText.value);
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate Testimonials :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  } else {
    translationText.value = translationTextEn.value;
  }
};

const products = ref([]);
const languageUrl = userLanguages.getLanguageCode ? `/${userLanguages.getLanguageCode}` : '';
const reqUserType = userLogin.checkUserType? `/${userLogin.checkUserType}`: '/customer';

// Fetch products from the API
const fetchProducts = async () => {
  try {
    const response = await axios.get(`${props.baseURL}products/reviewlist${reqUserType}${languageUrl}`); // Your products API endpoint
    products.value = response.data.products;  // Assuming response contains a list of products
    fetchReviewsForProducts();  // Fetch reviews for each product
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

// Fetch reviews for each product based on their `pid`
const fetchReviewsForProducts = async () => {
  for (let product of products.value) {
    try {
      const languageUrl = userLanguages.getLanguageCode ? `/${userLanguages.getLanguageCode}` : '';
      const response = await axios.get(`${props.baseURL}review/testimonials/${product.id}${languageUrl}`);
      product.reviews = response.data.user_reviewes;
    } catch (error) {
      console.error(`Error fetching reviews for product ${product.pid}:`, error);
      product.reviews = [];  // In case of error, make sure to set an empty array
    }
  }
};

watch(languageCode, () => {
  location.reload();
  //translatText();
});


onMounted(() => {
  translatText();
  fetchProducts();
});
</script>
